<template>
  <div class="mod-config">
    <el-form
      :inline="true"
      :model="dataForm"
      @keyup.enter.native="getDataList()"
    >
      <el-form-item>
        <el-input
          v-model="dataForm.keyword"
          placeholder="按车牌号搜索"
          clearable
        ></el-input>
      </el-form-item>
      <el-form-item>
        <el-select
          v-model="dataForm.pid"
          filterable
          clearable=""
          placeholder="按推广者筛选"
        >
          <el-option
            v-for="item in userList"
            :key="item.value"
            :label="item.label"
            :value="item.value"
          >
          </el-option>
        </el-select>
      </el-form-item>
      <el-form-item>
        <el-button @click="getDataList()">查询</el-button>
        <el-button type="primary" @click="addOrUpdateHandle()">新增</el-button>
      </el-form-item>
    </el-form>
    <el-table
      :data="dataList"
      border
      v-loading="dataListLoading"
      style="width: 100%"
    >
      <el-table-column
        prop="id"
        header-align="center"
        align="center"
        label="编号"
      >
      </el-table-column>
      <el-table-column
        prop="number"
        header-align="center"
        align="center"
        label="车牌号"
      >
      </el-table-column>

      <el-table-column
        prop="brand"
        header-align="center"
        align="center"
        label="品牌"
      >
      </el-table-column>
      <el-table-column
        prop="model"
        header-align="center"
        align="center"
        label="型号"
      >
      </el-table-column>
      <el-table-column
        prop="color"
        header-align="center"
        align="center"
        label="颜色"
      >
      </el-table-column>
      <el-table-column
        prop="registerDate"
        header-align="center"
        align="center"
        label="车辆注册时间"
      >
        <template slot-scope="scope">
          <span>{{
            scope.row.registerDate
              ? scope.row.registerDate.substring(0, 10)
              : ""
          }}</span>
        </template>
      </el-table-column>
      <el-table-column
        prop="nianjianDate"
        header-align="center"
        align="center"
        label="上次年检时间"
      >
        <template slot-scope="scope">
          <span>{{
            scope.row.nianjianDate
              ? scope.row.nianjianDate.substring(0, 10)
              : ""
          }}</span>
        </template>
      </el-table-column>
      <el-table-column
        prop="owner"
        header-align="center"
        align="center"
        label="车主"
      >
      </el-table-column>
      <el-table-column
        prop="phone"
        header-align="center"
        align="center"
        label="车主联系方式"
      >
      </el-table-column>
      <el-table-column
        prop="popularizeName"
        header-align="center"
        align="center"
        label="推广人"
      >
      </el-table-column>
      <el-table-column
        prop="createDate"
        header-align="center"
        align="center"
        label="创建时间"
      >
        <template slot-scope="scope">
          <span>{{
            scope.row.createDate ? scope.row.createDate.substring(0, 10) : ""
          }}</span>
        </template>
      </el-table-column>
      <!-- <el-table-column
        fixed="right"
        header-align="center"
        align="center"
        width="150"
        label="操作"
      >
        <template slot-scope="scope">
          <el-button
            type="text"
            size="small"
            @click="addOrUpdateHandle(scope.row.id)"
            >修改</el-button
          >
          <el-button
            type="text"
            size="small"
            @click="deleteHandle(scope.row.id)"
            >删除</el-button
          >
        </template>
      </el-table-column> -->
    </el-table>
    <div style="text-align: center; margin-top: 10px">
      <el-pagination
        @size-change="sizeChangeHandle"
        @current-change="currentChangeHandle"
        :current-page="pageIndex"
        :page-sizes="[10, 20, 50, 100]"
        :page-size="pageSize"
        :total="totalPage"
        layout="total, sizes, prev, pager, next, jumper"
      >
      </el-pagination>
    </div>
    <!-- 弹窗, 新增 / 修改 -->
    <!-- <add-or-update
      v-if="addOrUpdateVisible"
      ref="addOrUpdate"
      @refreshDataList="getDataList"
    ></add-or-update> -->
  </div>
</template>

<script>
// import AddOrUpdate from "./vehicle-add-or-update";
import { getVehicleList, deleteVehicleById, getUserList } from "../../api/api";
export default {
  data() {
    return {
      userList: [],
      dataForm: {
        keyword: "",
        pid: "",
      }, //查询参数
      dataList: [], //列表数据
      pageIndex: 1,
      pageSize: 10,
      totalPage: 0,
      dataListLoading: false,
      addOrUpdateVisible: false,
    };
  },
  // components: {
  //   AddOrUpdate,
  // },
  created() {
    this.getDataList();
    this.getUserList();
  },
  methods: {
    // 获取推广者列表
    getUserList() {
      getUserList({ type: 5 }).then((data) => {
        if (data && data.code === 0) {
          for (let item of data.data.list) {
            let userOps = {};
            userOps.value = item.id;
            userOps.label =
              item.nickName +
              "(" +
              (item.realName ? item.realName : "未实名") +
              ")";
            this.userList.push(userOps);
          }
        } else {
          this.userList = [];
        }
      });
    },
    // 获取数据列表
    getDataList() {
      this.dataListLoading = true;
      this.dataForm.page = this.pageIndex;
      this.dataForm.limit = this.pageSize;
      getVehicleList(this.dataForm).then((data) => {
        if (data && data.code === 0) {
          this.dataList = data.data.list;
          this.totalPage = data.data.totalCount;
        } else {
          this.dataList = [];
          this.totalPage = 0;
        }
        this.dataListLoading = false;
      });
    },
    // 每页数
    sizeChangeHandle(val) {
      this.pageSize = val;
      this.pageIndex = 1;
      this.getDataList();
    },
    // 当前页
    currentChangeHandle(val) {
      this.pageIndex = val;
      this.getDataList();
    },
    // 新增 / 修改
    addOrUpdateHandle(id) {
      this.addOrUpdateVisible = true;
      this.$nextTick(() => {
        this.$refs.addOrUpdate.init(id);
      });
    },
    // 删除
    deleteHandle(id) {
      this.$confirm(`确定要进行删除吗？`, "提示", {
        confirmButtonText: "确定",
        cancelButtonText: "取消",
        type: "warning",
      }).then(() => {
        deleteVehicleById({
          id,
        }).then((data) => {
          if (data && data.code === 0) {
            this.$message({
              message: data.msg,
              type: "success",
              duration: 1500,
              onClose: () => {
                this.getDataList();
              },
            });
          } else {
            this.$message.error(data.msg);
          }
        });
      });
    },
  },
};
</script>
